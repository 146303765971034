import React from 'react';

const TACeng = () => {
    return (
        <span className="text-xs">
          1. By accepting these terms and conditions you agree to participate in the joint offer provided by the
            JSC “Silknet” (ID: 204566978, Registration address: №95, Tsinamdzgvrishvili st., Tbilisi, Georgia)
            and JSC “Wissol Petroleum Georgia” (ID: 202161098, Registration address: №74b, Chavchavadze ave, Tbilisi, Georgia)
            and hereby agree to the terms and conditions of the Silknet and Wissol Joint Loyalty Program.
            <br/>
          2.	Wissol and Silknet  are authrosed to unilaterally, severally, independent from you or each other determine sales,
            discounts, offers, benefits within the scope of Silknet and Wissol Loyalty Program, determine terms and conditions
            for enjoying such sales, discounts, offers and benefits, amend or revoke such sales, discounts, offers or benefits,
            their terms and conditions.
            <br/>
          3.	To participate in Silknet and Wissol Loyalty Program you must:<br/>
                3.1. At the moment of registration be using mobile communication services based on a valid and enforceable Subscriber
                     Agreement for the Provision of Mobile Communication Service between you/third party and Silknet and be using a
                      mobile subscriber number registered in Silknet’s network;<br/>
                3.2. For the purposes of registration fill in all necessary fields as indicated on the web-site
                      https://discount.silknet.com/, including your first and last name, identification umber, date of birth,
                      a mobile subscriber number registered in Silknet’s network;
                3.3. Read and accept the terms and condition Terms and Conditions of Silknet and Wissol Loyalty Program,
                      including the information regarding personal data processing within the scope of the Progrma.
            <br/>
          4.	Following successful completion of the above steps your monitor will display a confirmation on successful
          registration within the scope of Silknet and Wissol Loyalty Program, you will be assigned a unique code, a QR code and
          your virtual card will be created and activated within Wissol Loyalty mobile app and MySilknet app.
            <br/>
          5.	Following successful registration within the scope of Silknet and Wissol Loyalty Program, you may use your unique code,
          a QR code and/or your virtual card and receive various sales, discounts, offers and/or benefits determined
          by Wissol or Silknet. In particular, within the scope of Silknet and Wissol Loyalty Program you will receive discounts
          at Wissol Petrol Stations when purchasing petrol, whereas Silknet will offer you various benefits within the scope
          of mobile communication services related to your mobile subscriber number.
            <br/>
          6.	Your unique code, QR code and your virtual card may be deactivated if:<br/>
            6.1.	If for any reason the Subscriber Agreement for Provision of Mobile Communication Service between you/third party
            and Silknet is terminated;<br/>
            6.2.	If you address Silknet and/or Wissol in writing at their respective addresses and request deactivation of
            your unique code, QR code and your virtual card;<br/>
            6.3.	If you address Silknet and/or Wissol in the form determined by the respective company and request termination
            of processing of your personal data or the destruction thereof;<br/>
            6.4.	If Silknet and Wissol Loyalty Program is canceled or terminated for whatever reason.<br/>

            7.	Deactivation of your unique code, QR code and your virtual card means that Wissol and Silknet will no
            longer be able to offer you sales, discounts and various offers within the scope of the Program until your card
            is reactivated. <br/>

            8.	Your unique code, QR code and your virtual card may be reactivated based on your request
            (to recover your unique code, QR code and virtual card and to allow you to enjoy the terms of Silknet and Wissol Loyalty Protam).
            if all grounds for deactivation no longer exist. <br/>

            9.	Silknet and Wissol are authorized unilaterally and without your consent to amend and/or revoke at any time the terms
            and conditions of the Program, determine new rules and conditions, which will be notified to you in writing,
            on the subscriber numbers indicated by you within the scope of the Program and/or will be published on the website(s)
            of Silknet and/or Wissol.

          </span>
    );
};

export default TACeng;
