import React from 'react';

const TACge = () => {
    return (
        <span className="text-xs">
          1. ამ პირობების დადასტურებით თქვენ აცხადებთ თანხმობას, სს
            „სილქნეტისა“ (ს/ნ 204566978; იურიდიული მისამართი: საქართველო, ქ.
            თბილისი, წინამძღვრიშვილის ქ., №95) (შემდგომში „სილქნეტი“) და სს
            „ვისოლ პეტროლიუმ ჯორჯიას“ (ს/ნ 202161098; იურიდიული მისამართი:
            საქართველო, თბილისი, ჭავჭავაძის გამზ., №74ბ) (შემდგომში „ვისოლი“)
            ერთობლივი შეთავაზებაში (შემდგომში „სილქნეტისა და ვისოლის ერთობლივი
            ლოიალობის პროგრამა“) მონაწილეობის მიღებაზე და ეთანხმებით სილქნეტისა
            და ვისოლის ერთობლივი ლოიალობის პროგრამის წესებს და პირობებს.
            <br/>
            2. მიზნით აუცილებელია: <br/>2.1. რეგისტრაციის მომენტისათვის სარგებლობდეთ
            სილქნეტის მობილური საკომუნიკაციო მომსახურებით და გქონდეთ შესაბამისი
            მობილური სააბონენტო ნომერი; <br/>2.2. რეგისტრაციის მიზნებისათვის შეავსოთ
            ვებ-გვერდზე https://discount.silknet.com/ მითითებული ყველა
            აუცილებელი ველი, მათ შორის მიუთითოთ თქვენი სახელი და გვარი, პირადი
            ნომერი, დაბადების თარიღი, სილქნეტის მობილურ საკომუნიკაციო ქსელში
            რეგისტრირებული აქტიური მობილური სააბონენტო ნომერი; <br/>2.3. დაეთანხმოთ
            თქვენი პერსონალური მონაცემების დამუშავებას და სილქნეტისა და ვისოლის
            ერთობლივი ლოიალობის პროგრამის წესებსა და პირობებს. <br/>3. ყველა
            აღნიშნული ეტაპის წარმატებით გავლის შედეგად თქვენს ეკრანზე გამოჩნდება
            სილქნეტისა და ვისოლის ერთობლივი ლოიალობის პროგრამის ფარგლებში
            წარმატებით რეგისტრაციის დასტური, თქვენ მოგენიჭებათ უნიკალური კოდი,
            QR კოდი და ვისოლის ლოიალობის მობილური აპლიკაციის თქვენს ანგარიშზე
            შეიქმნება შესაბამისი ვირტუალური ბარათი. <br/>4. თქვენ უფლებამოსილი ხართ
            სილქნეტისა და ვისოლის ერთობლივი ლოიალობის პროგრამის ფარგლებში
            ისარგებლოთ თქვენთვის მონიჭებული და აქტიური უნიკალური კოდით, აქტიური
            QR კოდით ან/და შესაბამისი აქტიური ვირტუალური ბარათით. კერძოდ, თქვენ
            უფლებამოსილი ხართ ისარგებლოთ ვისოლის ან/და სილქნეტის მიერ ცალმხრივად
            და ერთპიროვნულად განსაზღვრული სხვადასხვა ფასდაკლებით, შეღავათით,
            შეთავაზებით და ბენეფიტით. <br/>5. თქვენთვის მონიჭებული უნიკალური კოდი, QR
            კოდი და შესაბამისი ვირტუალური ბარათი შესაძლოა დაიბლოკოს (უვადოდ
            შეგიჩერდეთ უნიკალური კოდის, QR კოდის და შესაბამისი ვირტუალური
            ბარათის მოქმედება და ვეღარ შეძლოთ სილქნეტისა და ვისოლის ერთობლივი
            ლოიალობის პროგრამით სარგებლობა) იმ შემთხვევაში, თუ: <br/>5.1. თქვენ
            შეწყვეტთ სილქნეტის მობილური საკომუნიკაციო მომსახურებით სარგებლობას
            ნებისმიერი საფუძვლით, მათ შორის სილქნეტის მიერ შესაბამისი მობილური
            საკომუნიკაციო მომსახურების ხელშეკრულებითა და ელექტრონული
            კომუნიკაციების სფეროს მარეგულირებელი კანონმდებლობის (მათ შორის
            კანონქვემდებარე აქტების) საფუძველზე მომსახურების ცალმხრივად
            შეწყვეტის შემთხვევაში. <br/>5.2. თქვენ მოითხოვთ თქვენი პერსონალური
            მონაცემების სრულად ან ნაწილობრივ დამუშავების შეწყვეტას; <br/>5.3. თქვენ
            სიქლნეტისა და/ან ვისოლისათვის ამ კომპანიების ზემოაღნიშნულ
            მისამართებზე წერილობით მიმართვის გზით მოითხოვთ უნიკალური კოდის, QR
            კოდის და/ან შესაბამისი ვირტუალური ბარათის დაბლოკვას; <br/>5.4. სილქნეტისა
            და ვისოლის ერთობლივი ლოიალობის პროგრამა დასრულდება ან შეწყვეტს
            მოქმედებას ნებისმიერი მიზეზით. <br/>6. თქვენთვის მონიჭებული უნიკალური
            კოდი, QR კოდი და შესაბამისი ვირტუალური ბარათი შესაძლოა კვლავ
            გააქტიურდეს (აღდგეს უნიკალური კოდის, QR კოდის და შესაბამისი
            ვირტუალური ბარათის მოქმედება და კვლავ შეძლოთ სილქნეტისა და ვისოლის
            ერთობლივი ლოიალობის პროგრამით სარგებლობა) იმ შემთხვევაში, თუ
            აღმოიფხვრება დაბლოკვის ყველა საფუძველი. <br/>7. ვისოლი და სილქნეტი
            უფლებამოსილი არიან ერთპიროვნულად, ცალმხრივად, ერთმანეთისაგან და
            თქვენგან დამოუკიდებლად დაადგინონ, შეცვალონ ან გააუქმონ თქვენთვის
            შეთავაზებული ბენეფიტები, ფასდაკლებები, შეღავათები, შეთავაზებები,
            დაადგინონ შესაბამისი წესები და პირობები, შეცვალონ ან გააუქმონ
            წინამდებარე წესები და პირობები და სხვა. ასეთ შემთხვევაში თქვენ არ
            წარმოგეშვებათ რაიმე უფლება ან მოთხოვნა სილქნეტის ან/და ვისოლის
            მიმართ.
          </span>
    );
};

export default TACge;
