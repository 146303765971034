import React from "react";
import RegistrationForm from "./components/RegistrationForm";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./lang/en.json";
import kaTranslation from "./lang/ka.json";

// Initialize i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ka: {
        translation: kaTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    fallbackLng: "en", // Set fallback language
    debug: false, // Enable debug mode
  });

const App = () => {
  return (
    <div className="app">
      <div className="app-content">
        <RegistrationForm />
      </div>
    </div>
  );
};

export default App;
