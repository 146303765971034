import React from "react";

const PInfoGe = () => {
  return (
      <div style={{lineHeight: "1.5", fontSize: "16px"}}>
          <p>
              გაცნობებთ, რომ სს „სილქნეტისა“ (ს/ნ 204566978; იურიდიული მისამართი: საქართველო,
              ქ. თბილისი, წინამძღვრიშვილის ქ., №95) და სს „ვისოლ პეტროლიუმ ჯორჯიას“ (ს/ნ 202161098;
              იურიდიული მისამართი: საქართველო, თბილისი, ჭავჭავაძის გამზ., №74ბ) <strong>ერთობლივ შეთავაზებაში </strong>
              (შემდგომში „სილქნეტისა და ვისოლის ლოიალობის პროგრამა“/ „პროგრამა“) <strong>მონაწილეობის მიღების
              და ამ შეთავაზებით სარგებლობის მიზნით</strong> აუცილებელია სს „სილქნეტმა“ (სილქნეტი) და სს
              “ვისოლ პეტროლიუმ ჯორჯიამ“ (ვისოლი) დაამუშაონ თქვენი პერსონალური მონაცემები.
          </p>
          <h2 style={{display: "inline", margin: "0"}}>
              1. ტერმინთა განმარტება:
          </h2>
          <p>
              1.1. <strong>პერსონალური მონაცემები</strong> - რომელთა დამუშავებაც საჭიროა <i>სილქნეტისა და ვისოლის
              ლოიალობის
              პროგრამაში</i> რეგისტრაციისა და მონაწილეობის მისაღებად. კერძოდ, მე-2 პუნქტში მითითებული მონაცემები.
          </p>
          <p>
              1.2. <strong>მონაცემთა დამუშავება</strong> - პერსონალურ მონაცემთა მიმართ შესრულებული ნებისმიერი მოქმედება,
              მათ შორის,
              მათი შეგროვება, მოპოვება, მათზე წვდომა, შენახვა, გადაცემა ან პერსონალური მონაცემების ნებისმიერი სხვა სახით
              გამოყენება.
          </p>
          <p>
              1.3. <strong>ინციდენტი</strong> - მონაცემთა უსაფრთხოების დარღვევა, რომელიც იწვევს მონაცემების
              არამართლზომიერ ან შემთხვევით დაზიანებას,
              დაკარგვას, აგრეთვე უნებართვო გამჟღავნებას, განადგურებას, შეცვლას, მათზე წვდომას, მათ შეგროვებას/მოპოვებას
              ან სხვაგვარ უნებართვო დამუშავებას;
          </p>
          <h2 style={{display: "inline", margin: "0"}}>2.</h2> <i>„სილქნეტისა და
          ვისოლის ლოიალობის პროგრამის ფარგლებში"</i> სილქნეტი და ვისოლი დაამუშავებენ
          შემდეგ პერსონალურ მონაცემებს:
          <p>
              2.1. <strong>სარეგისტრაციო მონაცემები</strong>: სახელი და გვარი; პირადი ნომერი, მობილური სააბონენტო
              ნომერი, დაბადების თარიღი.
          </p>
          <p>
              2.2. <strong>ტრანზაქციული მონაცემები</strong>: თქვენ მიერ სილქნეტისა და ვისოლის ლოიალობის პროგრამის
              ფარგლებში ვისოლის ავტოგასამართ
              სადგურზე განხორციელებულ ტრანზაქციასთან დაკავშირებული მონაცემები (შეძენილი საწვავის ოდენობა, საწვავის ტიპი,
              შეძენის დრო, ადგილი, გადახდის ტიპი (უნაღდო/ნაღდი ანგარიშსწორება), კომერციული ბანკი).
          </p>
          <h2 style={{display: "inline", margin: "0"}}>3.</h2> 2.1 პუნქტით
          გათვალისწინებულ პერსონალურ მონაცემებს <i>სილქნეტი</i> გადასცემს <i>ვისოლს</i>, ხოლო
          2.2. პუნქტში მითითებულ ინფორმაციას <i>სილქნეტი</i> მიიღებს <i>ვისოლისაგან</i>.
          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>4.</h2> მონაცემების
          დამუშავების, მათ შორის, გადაცემის მიზანია (<strong>"მიზნები“</strong>):
          <p>
              4.1. წარმატებით დარეგისტრირდეთ და მონაწილეობა მიიღოთ <i>სილქნეტისა და ვისოლის ლოიალობის პროგრამაში </i>
              (პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს კანონის მუხლი 5.ბ);
          </p>
          <p>
              4.2. <i>სილქნეტისა და ვისოლის ლოიალობის პროგრამის</i> ფარგლებში
              ისარგებლოთ <i>ვისოლის</i> ან/და <i>სილქნეტის</i> მიერ
              განსაზღვრული სხვადასხვა ფასდაკლებით, შეღავათით, შეთავაზებით და მიიღოთ ბენეფიტები, ასევე <i>ვისოლისა</i>
              და <i>სილქნეტის</i> მიერ ხელშეკრულებით დაკისრებული სხვა მოვალეობის შესასრულებლად (პერსონალურ მონაცემთა
              დაცვის შესახებ საქართველოს კანონის მუხლი 5.ბ);
          </p>
          <p>
              4.3. <i>ვისოლისა და სილქნეტის</i> მიერ საქართველოს კანონმდებლობით დაკისრებული მოვალეობის შესასრულებლად
              (პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს კანონის მუხლი 5.გ);
          </p>
          <p>
              4.4. ნებისმიერი სხვა ლეგიტიმური მიზნით, პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს კანონმდებლობის
              მოთხოვნების
              შესაბამისად (პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს კანონის მუხლი 5.ი).
          </p>
          <h2 style={{display: "inline", margin: "0"}}>5.</h2> პერსონალური მონაცემების დამუშავება მოხდება
          კონფიდენციალურობისა
          და მონაცემთა დამუშავებისას უსაფრთხოების ყველა სტანდარტის დაცვით.
          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>6.</h2> თქვენი პერსონალური მონაცემები ინახება მხოლოდ იმ ვადით,
          რომელიც აუცილებელია მე-4 პუნქტში მითითებული მიზნების მისაღწევად, გარდა იმ შემთხვევისა,
          როდესაც საქართველოს კანონმდებლობით დადგენილია მონაცემთა დამუშავების განსხვავებული ვადა.
          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>7.</h2> გაცნობებთ, რომ თქვენ უფლებამოსილი ხართ ნებისმიერ დროს
          გამოითხოვოთ ინფორმაცია თქვენი პერსონალური მონაცემების დამუშავების თაობაზე, მოითხოვოთ პერსონალური მონაცემების
          გასწორება,
          განახლება, დამატება, დაბლოკვა, წაშლა/განადგურება. გაითვალისწინეთ, რომ მონაცემების წაშლა/განადგურებამ შესაძლოა
          გავლენა
          იქონიოს პროგრამის ფარგლებში თქვენს მონაწილეობასა და პროგრამის პირობებით სარგებლობაზე. მაგალითად, თუ თქვენ
          მოითხოვთ თქვენი პერსონალური მონაცემების დაბლოკვას, წაშლას ან მათ განადგურებას, თქვენთვის მონიჭებული უნიკალური
          კოდი,
          QR კოდი და შესაბამისი ვირტუალური ბარათი შესაძლოა დაიბლოკოს (უვადოდ შეგიჩერდეთ უნიკალური კოდის, QR კოდის და
          შესაბამისი ვირტუალური ბარათის მოქმედება და ვეღარ შეძლოთ <i>სილქნეტისა და ვისოლის ერთობლივი ლოიალობის
          პროგრამით</i> სარგებლობა),
          თქვენი ანგარიში წაიშალოს ან გაუქმდეს.
          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>8.</h2> სილქნეტის მიერ თქვენ მონაცემთა დამუშავებასთან
          დაკავშირებით თქვენ შეგიძლიათ
          მიმართოთ სილქნეტს მის იურიდიულ მისამართზე: საქართველო, ქ. თბილისი, წინამძღვრიშვილის ქ., №95, ეწვიოთ სილქნეტის
          ნებისმიერ ფილიალს,
          დაუკავშირდეთ კომპანიას მის ცხელ ხაზზე: 032 2 100 100; 110 110 ან მიმართოთ ელ-ფოსტით შემდეგ მისამართებზე: dpo@silknet.com,
          ან info@silknet.com.
          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>9.</h2> გაცნობებთ,
          რომ <i>სილქნეტი</i> და <i>ვისოლი</i> დამოუკიდებლად ამუშავებენ პერსონალურ მონაცემებს
          და წარმოადგენენ ერთმანეთისაგან დამოუკიდებელ მონაცემთა დამმუშავებლებს. თითოეული მათგანი დამოუკიდებლად და
          ერთპიროვნულად განსაზღვრავს
          მისი კონტროლის ქვეშ მყოფი პერსონალური მონაცემების დამუშავების მიზანსა და საშუალებას პერსონალურ მონაცემთა
          დაცვის შესახებ კანონმდებლობისა
          და შესაბამისი მხარის პოლიტიკ(ებ)ის, მათ შორის კონფიდენციალობის/პერსონალურ მონაცემთა დაცვის განაცხადის
          შესაბამისად. <p></p>

          სილქნეტის კონფიდენციალურობის/პერსონალური მონაცემების დაცვის განაცხადი შეგიძლიათ იხ: https://silknet.com/ge/company/privacy-policy. <p></p>

          არცერთი მონაცემთა დამმუშავებელი არ არის პასუხისმგებელი მეორე მონაცემთა დამმუშავებლის მიერ პერსონალურ მონაცემთა
          დაცვის შესახებ კანონმდებლობის, მათ შორის კანონის მოთხოვნათა დარღვევისათვის.

          <p></p>
          <h2 style={{display: "inline", margin: "0"}}>10.</h2> <i>ვისოლის</i> მიერ თქვენ მონაცემთა დამუშავებასთან დაკავშირებით შეგიძლიათ
          მიმართოთ <i>ვისოლს</i> მის იურიდიული მისამართზე: საქართველო, თბილისი, ჭავჭავაძის გამზ., №74ბ.
      </div>
  );
};

export default PInfoGe;
