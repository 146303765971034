import React from "react";
import { useTranslation } from "react-i18next";

const ErrorForm = ({ responseMessages }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-2">
      <div
        className="align-items-center justify-content-center"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="error-message mb-2 text-lg">{t('notification.message')}</div>
        <div className="error-message text-xs">{responseMessages}</div>
      </div>
    </div>
  );
};

export default ErrorForm;
