import {
  locale,
  addLocale,
} from "primereact/api";

export const georgianLocalizationCalendar = () => {
  addLocale("ka", {
    dayNames: [
      "ორშაბათი",
      "სამშაბათი",
      "ოთხშაბათი",
      "ხუთშაბათი",
      "პარასკევი",
      "შაბათი",
      "კვირა",
    ],
    dayNamesShort: ["ორშ", "სმბ", "ოთხ", "ხთბ", "პრკ", "შბმ", "კვრ"],
    dayNamesMin: ["ორშ", "სმბ", "ოთხ", "ხთბ", "პრკ", "შბმ", "კვრ"],
    monthNames: [
      "იანვარი",
      "თებერვალი",
      "მარტი",
      "აპრილი",
      "მაისი",
      "ივნისი",
      "ივლისი",
      "აგვისტო",
      "სექტემბერი",
      "ოქტომბერი",
      "ნოემბერი",
      "დეკემბერი",
    ],
    monthNamesShort: [
      "იან",
      "თებ",
      "მარ",
      "აპრ",
      "მაი",
      "ივნ",
      "ივლ",
      "აგვ",
      "სექ",
      "ოქტ",
      "ნოე",
      "დეკ",
    ],
    today: "დღეს",
    clear: "გასუფთავება",
    dateFormat: "dd/mm/yy",
    weekHeader: "კვირა",
  });

  locale("ka");
};
