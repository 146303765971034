export function convertToGeorgian(input) {
  const georgianLetters = {
    a: 'ა',
    b: 'ბ',
    c: 'ც',
    d: 'დ',
    e: 'ე',
    f: 'ფ',
    g: 'გ',
    h: 'ჰ',
    i: 'ი',
    j: 'ჯ',
    k: 'კ',
    l: 'ლ',
    m: 'მ',
    n: 'ნ',
    o: 'ო',
    p: 'პ',
    q: 'ქ',
    r: 'რ',
    s: 'ს',
    t: 'ტ',
    u: 'უ',
    v: 'ვ',
    w: 'წ',
    x: 'ხ',
    y: 'ყ',
    z: 'ზ',
    S: 'შ',
    Z: 'ძ',
    W: 'ჭ',
    T: 'თ',
    J: 'ჟ',
    R: 'ღ',
    C: 'ჩ'
  };

  const russianLetters = {
    я: 'ყ',
    ч: 'ჩ',
    с: 'ს',
    м: 'მ',
    и: 'ი',
    т: 'ტ',
    ь: 'ჭ',
    б: 'ბ',
    ю: 'ჲ',
    э: 'ე',
    ж: 'ჟ',
    х: 'ხ',
    ъ: 'ხ',
    ф: 'ფ',
    ы: 'ყ',
    в: 'ვ',
    а: 'ა',
    п: 'პ',
    р: 'რ',
    о: 'ო',
    л: 'ლ',
    д: 'დ',
    ж: 'ჟ',
    э: 'ე',
    к: 'კ',
    у: 'უ',
    е: 'ე',
    н: 'ნ',
    г: 'გ',
    ш: 'შ',
    щ: 'შ',
    з: 'ზ',
    й: 'ჟ',
    ц: 'ც',
    й: 'ჯ',
    ё: 'ჱ',
    й: 'ჯ',
    ё: 'ჸ',
    ы: 'ყ',
    в: 'ვ',
    м: 'მ',
    и: 'ი',
    т: 'თ',
    ь: 'ჭ',
    б: 'ბ',
    ю: 'ყ'
  };

  const converted = input.split('').map((letter) => {
    if (georgianLetters.hasOwnProperty(letter)) {
      return georgianLetters[letter];
    } else if (georgianLetters.hasOwnProperty(letter.toLowerCase())) {
      return georgianLetters[letter.toLowerCase()];
    } else if (russianLetters.hasOwnProperty(letter)) {
      return russianLetters[letter];
    } else if (russianLetters.hasOwnProperty(letter.toLowerCase())) {
      return russianLetters[letter.toLowerCase()];
    }
    return letter;
  });

  return converted.join('');
}