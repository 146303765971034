import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import "./index.css";
import { useRegisterMobileNumberMutation } from "../../api/registration";
import { InputMask } from "primereact/inputmask";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { validateForm } from "../../utils/validation";
import { georgianLocalizationCalendar } from "../../utils/georgianLocalizationCalendar";
import { convertToGeorgian } from "../../utils/convertToGeorgian";
import ModalComponent from "../ModalComponent";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const RegistrationForm = () => {
  const { t } = useTranslation();
  const [registerMobileNumber] = useRegisterMobileNumberMutation(); // Use the mutation from RTK Query

  const toast = useRef(null);
  const [checked, setChecked] = useState(false);
  const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] =
    useState(false);
  const [agreedToTransfer, setAgreedToTransfer] = useState(false);
  const [formValues, setFormValues] = useState({
    mobileNumber: "",
    personalID: "",
    firstName: "",
    lastName: "",
    birthDate: null,
    agreeToTermsAndConditions: false,
    agreedToTransfer: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showTermsAndConditionsModal, setTermsAndConditionsModal] =
    useState(false);
  const [showAgreedToTransferModal, setShowAgreedToTransferModal] =
    useState(false);   
  const [showQrcodesModal, setShowQrCodeModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [registrationData, setRegistrationData] = useState(null);
  georgianLocalizationCalendar();

  const [selectedLanguage, setSelectedLanguage] = useState("ka");
  const prevSelectedLanguage = useRef(selectedLanguage);
  const currentDate = new Date();

  const [modalType, setModalType] = useState("registrationForm");

  const handleChangeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    if (prevSelectedLanguage.current !== selectedLanguage) {
      setFormErrors({});
      prevSelectedLanguage.current = selectedLanguage;
    }
  }, [selectedLanguage]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    let convertedValue = value;

    if (id === "firstName" || id === "lastName") {
      convertedValue = convertToGeorgian(value);
    }

    // Update form values
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: convertedValue,
    }));

    // Validate the field and update form errors
    const validationErrors = validateForm(
      {
        ...formValues,
        [id]: convertedValue,
      },
      t
    );
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: validationErrors[id],
    }));
  };

  const handleBlur = (e) => {
    const { id, value } = e.target;
    // Validate the field and update form errors
    const validationErrors = validateForm(
      {
        ...value,
        [id]: value,
      },
      t
    );
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: validationErrors[id],
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formValues, t);
    setFormErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const dateOnly = new Date(formValues.birthDate).toISOString().split("T")[0];

    const dataToPost = {
      ...formValues,
      birthDate: dateOnly,
    };

    const mutationResult = await registerMobileNumber(dataToPost);

    if (!mutationResult?.data?.result) {
      toast.current.show({
        severity: "error",
        summary: `${t("notification.error")}`,
        detail: mutationResult?.data?.message,
        sticky: true,
      });
    } else {
      const code = mutationResult.data.verificationCode;
      setFormValues((prevValues) => ({
        ...prevValues,
        verificationCode: code,
      }));

      // Store the registration data
      setRegistrationData(mutationResult.data.data);
      setShowModal(true); // Show the modal
      setChecked(false);
    }
  };

  const handleModalSubmit = () => {
    // Handle pinCode submission
    // Call necessary APIs or perform additional steps
    setShowModal(false);
  };

  const handleAgreedToTransferCheckboxChange = (e) => {
    const { checked } = e.target;
    setAgreedToTransfer(checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      agreedToTransfer: checked,
    }));
  };

  const agreeToTermsAndConditionsCheckboxChange = (e) => {
    const { checked } = e.target;
    setAgreeToTermsAndConditions(checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      agreeToTermsAndConditions: checked,
    }));
  };

  const agreeToTransferDataCheckboxChange = (e) => {
    const { checked } = e.target;
    setAgreedToTransfer(checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      agreedToTransfer: checked,
    }));
  };

  const handleShowQrCodeModal = () => {
    setShowQrCodeModal(true);
    setModalType(undefined);
  };

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-5 shadow-2 border-round sm:w-3">
        <div className="text-center mb-2">
          <div className="text-900 text-xl font-medium mb-3 justify-content-between flex">
            <img
              src="../images/logos/silk.png"
              alt="Logo Text"
              className="logo-text"
            />
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              onChangeLanguage={handleChangeLanguage}
            />
          </div>
        </div>

        <form onSubmit={handleRegister} autoComplete="off">
          <div>
            <label
              htmlFor="mobileNumber"
              className="block text-700 font-small text-sm mb-1 font-bold"
            >
              {t("mobileNumber")}*
            </label>
            <InputMask
              id="mobileNumber"
              mask="999999999"
              value={formValues.mobileNumber}
              onChange={handleChange}
              slotChar=""
              className="w-full mb-1 p-inputtext-sm"
              placeholder="5XX XXXXXX"
              inputMode="numeric"
            />

            {formErrors.mobileNumber && (
              <div className="text-red-500 text-xs text-right">
                {formErrors.mobileNumber}
              </div>
            )}

            <label
              htmlFor="personalID"
              className="block text-700 font-small text-sm mb-1 font-bold"
            >
              {t("pid")}*
            </label>
            <InputMask
              id="personalID"
              value={formValues.personalID}
              onChange={handleChange}
              className="w-full mb-1 p-inputtext-sm"
              mask="99999999999"
              slotChar=""
              placeholder={t("pid")}
              inputMode="numeric"
            />

            {formErrors.personalID && (
              <div className="text-red-500 text-xs text-right">
                {formErrors.personalID}
              </div>
            )}

            <label
              htmlFor="firstName"
              className="block text-700 font-small text-sm mb-1 font-bold"
            >
              {t("name")}*
            </label>
            <InputText
              id="firstName"
              type="text"
              value={formValues.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full mb-1 p-inputtext-sm"
              placeholder={`${t("name")} (${t("onlyGeorgian")})`}
            />

            {formErrors.firstName && (
              <div className="text-red-500 text-xs text-right">
                {formErrors.firstName}
              </div>
            )}

            <label
              htmlFor="lastName"
              className="block text-700 font-small text-sm mb-1 font-bold"
            >
              {t("lastName")}*
            </label>
            <InputText
              id="lastName"
              type="text"
              value={formValues.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full mb-1 p-inputtext-sm"
              placeholder={`${t("lastName")} (${t("onlyGeorgian")})`}
            />

            {formErrors.lastName && (
              <div className="text-red-500 text-xs text-right">
                {formErrors.lastName}
              </div>
            )}

            <label
              htmlFor="birthDate"
              className="block text-700 font-small text-sm mb-1 font-bold"
            >
              {t("birthDate")}*
            </label>
            <Calendar
              id="birthDate"
              value={formValues.birthDate}
              onChange={handleChange}
              dateFormat="dd/mm/yy"
              className="w-full mb-1 custom-calendar text-xs"
              showIcon
              placeholder="DD/MM/YYYY"
              maxDate={currentDate}
              //readOnlyInput
            />
            {formErrors.birthDate && (
              <div className="text-red-500 text-xs text-right">
                {formErrors.birthDate}
              </div>
            )}

            <div className="mt-4 mb-3">
              <div className="flex align-items-center">
                <Checkbox
                  id="agreeToTermsAndConditions"
                  checked={formValues.agreeToTermsAndConditions}
                  className="mr-2"
                  onClick={() => setTermsAndConditionsModal(true)}
                />
                <label
                  htmlFor="agreeToTermsAndConditions"
                  className={classNames(
                    "text-xs",
                    "cursor-pointer",
                    "link-like"
                  )}
                  onClick={() => setTermsAndConditionsModal(true)}
                >
                  {t("termsAndConf")}
                </label>
              </div>
              {formErrors.agreeToTermsAndConditions &&
                !agreeToTermsAndConditions && (
                  <div className="text-red-500 text-xs text-right">
                    {formErrors.agreeToTermsAndConditions}
                  </div>
                )}
            </div>

            <div className="mt-2 mb-3">
              <div className="flex align-items-center">
                <Checkbox
                  id="agreedToTransfer"
                  checked={agreedToTransfer}
                  className="mr-2"
                  onClick={() => setShowAgreedToTransferModal(true)}
                />
                <label
                  htmlFor="agreedToTransfer"
                  className={classNames(
                    "text-xs",
                    "cursor-pointer",
                    "link-like"
                  )}
                  onClick={() => setShowAgreedToTransferModal(true)}
                >
                  {t("consent")}
                </label>
              </div>

              {formErrors.agreedToTransfer && !checked && (
                <div className="text-red-500 text-xs text-right">
                  {formErrors.agreedToTransfer}
                </div>
              )}
            </div>
            <div className="flex justify-content-center">
              <Button
                label={t("continue")}
                className="p-button-rounded p-button-sm p-button-info"
                type="submit"
              />
            </div>
          </div>
        </form>
        <div className="mt-4">
          <div className="justify-content-between flex">
            <label
              className={classNames(
                "text-xs",
                "cursor-pointer",
                "link-like",
                "mt-2"
              )}
              onClick={handleShowQrCodeModal}
            >
              {t("qrRecovery")}
            </label>
          </div>
        </div>
        <Toast ref={toast} position="center" />
      </div>
      <ModalComponent
        dialogId="showPinCodeFormModalId"
        visible={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={handleModalSubmit}
        pinCode={pinCode}
        setPinCode={setPinCode}
        registrationData={registrationData}
        toastRef={toast}
        typeOfModal={modalType}
      />
      {showTermsAndConditionsModal && (
        <ModalComponent
          dialogId="showTermsAndConditionsModalId"
          visible={showTermsAndConditionsModal}
          onHide={() => setTermsAndConditionsModal(false)}
          onSubmit={handleModalSubmit}
          pinCode={pinCode}
          setPinCode={setPinCode}
          registrationData={registrationData}
          agreeToTermsAndConditions={agreeToTermsAndConditions}
          agreeToTermsAndConditionsCheckboxChange={
            agreeToTermsAndConditionsCheckboxChange
          }
          agreeToTransferDataCheckboxChange={agreeToTransferDataCheckboxChange}
          agreedToTransfer={agreedToTransfer}
          agreedToTransferCheckboxChange={handleAgreedToTransferCheckboxChange}
          toastRef={toast}
        />
      )}
      {showAgreedToTransferModal && (
        <ModalComponent
          dialogId="showAgreedToTransferModalId"
          visible={showAgreedToTransferModal}
          onHide={() => setShowAgreedToTransferModal(false)}
          onSubmit={handleModalSubmit}
          pinCode={pinCode}
          setPinCode={setPinCode}
          registrationData={registrationData}
          agreeToTermsAndConditions={agreeToTermsAndConditions}
          agreeToTermsAndConditionsCheckboxChange={
            agreeToTermsAndConditionsCheckboxChange
          }
          agreeToTransferDataCheckboxChange={agreeToTransferDataCheckboxChange}
          agreedToTransfer={agreedToTransfer}
          agreedToTransferCheckboxChange={handleAgreedToTransferCheckboxChange}
          toastRef={toast}
        />
      )}
      {showQrcodesModal && (
        <ModalComponent
          dialogId="showQrCodeModalId"
          visible={showQrcodesModal}
          onHide={() => setShowQrCodeModal(false)}
          onSubmit={handleModalSubmit}
          pinCode={pinCode}
          setPinCode={setPinCode}
          registrationData={registrationData}
          toastRef={toast}
        />
      )}
      {showErrorModal && (
        <ModalComponent
          dialogId="showErrorModalId"
          visible={showErrorModal}
          onHide={() => setShowErrorModal(false)}
          onSubmit={handleModalSubmit}
          pinCode={pinCode}
          setPinCode={setPinCode}
          registrationData={registrationData}
          toastRef={toast}
        />
      )}
      {showLoadingModal && (
        <ModalComponent
          dialogId="showLoadingModalId"
          visible={showErrorModal}
          onHide={() => setShowLoadingModal(false)}
          onSubmit={handleModalSubmit}
          pinCode={pinCode}
          setPinCode={setPinCode}
          registrationData={registrationData}
          toastRef={toast}
        />
      )}
    </div>
  );
};

export default RegistrationForm;
