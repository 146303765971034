export const validateForm = (values, t) => { 
  const validationErrors = {};

  if (!values.mobileNumber) {
    validationErrors.mobileNumber = t("validation.mobileNumberRequired"); // Translate the error message
  } 
  // else if (!isValidMobileNumber(values.mobileNumber)) {
  //   validationErrors.mobileNumber = t("validation.mobileNumberInvalid");
  // }

  if (!values.personalID) {
    validationErrors.personalID = t("validation.personalIDRequired");
  } 
  // else if (!isValidPersonalID(values.personalID)) {
  //   validationErrors.personalID = t("validation.personalIDInvalid");
  // }

  if (!values.firstName) {
    validationErrors.firstName = t("validation.firstNameRequired");
  }

  if (!values.lastName) {
    validationErrors.lastName = t("validation.lastNameRequired");
  }

  if (!values.birthDate) {
    validationErrors.birthDate = t("validation.birthDateRequired");
  }

  if (!values.agreedToTransfer) {
    validationErrors.agreedToTransfer = t("validation.agreedToTransferRequired");
  }

  if (!values.agreeToTermsAndConditions) {
    validationErrors.agreeToTermsAndConditions = t("validation.agreeToTermsRequired");
  }

  return validationErrors;
};
