export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

export const focusNextInput = (index, inputRefs) => {
  if (index < inputRefs.current.length - 1) {
    inputRefs.current[index + 1].focus();
  }
};

export const focusPreviousInput = (index, inputRefs) => {
  if (index > 0) {
    inputRefs.current[index - 1].focus();
  }
};
