import React from 'react';

const PInfoEng = () => {
  return (
    <div style={{ lineHeight: '1.5', fontSize: '16px' }}>
      <p>
          In order to participate in the joint offer of  JSC „Silknet“ (ID No:204566978; Legal address:
          №95 Tsinamdzghvrishvili Str., Tbilisi Georgia) and JSC „Wissol Petroleum Georgia“ (ID No:202161098;
          Legal Address: №74B Chavchavadze Ave, Tbilisi, Georgia,) (hereinafter „Silknet and Wissol Loyalty
          Program“/ „Program“) and enjoy the benefits within the scope of the Program, JSC Silknet
          (hereinafter “Silknet”) and JSC Wissol Petroleum Georgia (hereinafter “Wissol”) require to process your personal data.
      </p>
      <h2 style={{ display: 'inline', margin: '0' }}>1. Definitions:</h2>
      <p>
        1.1. <strong>Personal Data</strong> – Data provided in Clause 2, necessary for registration and participation in Silknet and Wissol Loyalty Program.
      </p>
      <p>
        1.2. <strong>Data Processing</strong> – Any operation performed on personal data, including collection, access, recoding, sharing, or any use of personal data.
      </p>
      <p>
        1.3. <strong>Incident</strong> – a breach of security of personal data leading to the accidental or unlawful damaging, loss, alteration, unauthorized disclosure of,
          destruction, or access to, personal data or unauthorized processing thereof.
      </p>
      <h2 style={{ display: 'inline', margin: '0' }}>2.</h2> Within the scope of Silknet and Wissol Loyalty Program Silknet and Wissol will process the following personal data:
      <p>
        2.1. <strong>Registration data</strong> First and last name; Identification number, Mobile phone number, date of birth.
      </p>
      <p>
        2.2. <strong>Transaction data</strong> Data (volume of fuel purchased, type of fuel, time and place of purchase, form of payment (cash/card payment) commercial bank)
          related to the transactions made by you at the Wissol gas stations within the scope of the Program.
      </p>
      <h2 style={{ display: 'inline', margin: '0' }}>3.</h2> 	Personal Data provided in Clause 2.1. will be shared by Silknet with Wissol,
        whereas Personal Data in Clause 2.2. will be received by Silknet form Wissol.
        <p></p>
      <h2 style={{ display: 'inline', margin: '0' }}>4.</h2> The purposes for Personal Data processing, including sharing of Personal
        Data are the following (“Purposes”):
      <p>
          4.1.	To ensure your successful registration and participation in the Program (Article 5.b of the Law of
          Georgia on Personal Data Protection);
      </p>
      <p>
          4.2.	To ensure that you can enjoy various sales, promotions, offers and benefits within the scope of
          Silknet and Wissol Loyalty Program and the Silknet and Wissol perform the obligations under the
          relevant contract (Article 5.b of the Law of Georgia on Personal Data Protection);
      </p>
      <p>
          4.3.	To comply with obligations imposed upon Silknet and Wissol under the laws of Georgia
          (Article 5.c of the Law of Georgia on Personal Data Protection);
      </p>
      <p>
          4.4.	Any other legitimate purpose in accordance with the personal data protection legislation of Georgia
          (Article 5.i of the Law of Georgia on Personal Data Protection).
      </p>
      <h2 style={{ display: 'inline', margin: '0' }}>5.</h2> Personal Data will be processed in full compliance with all
        applicable confidentiality and data security standards.
        <p></p>
      <h2 style={{ display: 'inline', margin: '0' }}>6.</h2> Your Personal Data will be stored for the period that
        is necessary to achieve the purposes provided in Clause 4, unless the laws of Georgia provide other time
        limits for the processing of Personal Data.
        <p></p>
      <h2 style={{ display: 'inline', margin: '0' }}>7.</h2> You have the right to obtain information on the processing
        your Personal Data, request rectification, updating, addition, blocking or erasure/destruction of Personal Data.
        Note that the blocking/erasure/destruction of your Personal Data may impact your ability to participate and enjoy
        the benefits within the scope of the Program. For example, if you request blocking, erasure or destruction of
        your personal data, the unique code, QR code and the corresponding virtual card assigned to you may be blocked
        (you will be permanently suspended from the operation of the unique code, OR code and the corresponding virtual
        card and you will no longer be able to benefit from Silknet and Wissol Loyalty Program, your account will be
        deleted or terminated).
      <p></p>
      <h2 style={{ display: 'inline', margin: '0' }}>8.</h2> Regarding the processing of your Personal Data by Silknet,
        you may contact Silknet at its legal address: №95 Tsinamdzghvrishvili Str., Tbilisi Georgia or visit any
        of its offices. visit any branch of Silknet, contact the company on its hotline: 032 2 100 100; 110 110;
        Or send e-mail to the following addresses: dpo@silknet.com; info@silknet.com.
        <p></p>
        <h2 style={{display: 'inline', margin: '0' }}>9.</h2>  Note that Silknet and Wissol are processing Personal
        Data separately and independently and therefore, represent independent data processors. Each of them determines
        the purposes and the means for processing Personal Data independently, in accordance with the laws of Georgia
        and their policies, including their respective privacy/personal data protection statements. Silknet's Privacy
        Policy/Personal Data Protection Statement can be found at: https://silknet.com/ge/company/privacy-policy.
        None of the data processors shall be responsible for the breach of personal data protection laws by the other data processor.
        <p></p>
      <h2 style={{ display: 'inline', margin: '0' }}>10.</h2> Regarding the processing of your Personal Data by Silknet,
        you may address Wissol at its legal address: №74B Chavchavadze Ave, Tbilisi, Georgia.
    </div>
  );
};

export default PInfoEng;
