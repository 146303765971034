import React from "react";
import { formatTime, focusNextInput, focusPreviousInput } from "../helpers";
import { useTranslation } from "react-i18next";

const PinCodeForm = ({
  registrationData,
  pinCode,
  setPinCode,
  validationErrors,
  setValidationErrors,
  countdown,
  handleResend,
  formValues,
  responseMessages,
  setResponseMessages,
}) => {
  const inputRefs = React.useRef([]);
  const { t } = useTranslation();
  const handleKeyDown = (event, index) => {
    if (
      event.key === "Backspace" &&
      pinCode[index] === undefined &&
      index > 0
    ) {
      focusPreviousInput(index, inputRefs);
    }
  };

  const handleChangeOTP = (index, value) => {
    const newPinCode = [...pinCode]; // Create a new array to avoid mutating the state directly
    newPinCode[index] = value;
    setPinCode(newPinCode.join("").substring(0, 4)); // Limit pinCode to 4 digits

    if (value !== "" && index < 3) {
      focusNextInput(index, inputRefs);
    }

    // Check if all fields have a value
    if (newPinCode.every((code) => code !== "")) {
      // Clear the validation error message
      setValidationErrors({});
    }
  };

  React.useEffect(() => {
    if(responseMessages){
      setResponseMessages('')
    }
    return () => {
      setPinCode([]);
    };
  }, [setPinCode]);

  return (
    <div className="pin-code-inputs">
      <div className="pin-code-labels mb-5">
        <label className="block text-sm font-bold mb-1">
          {t("Enteraonetimecode")}
        </label>
        <label className="block text-xs">
          {t("codewassenttonumber")} -{" "}
          <span className="font-bold">
            {registrationData?.mob_num || formValues?.mobileNumber}
          </span>
        </label>
      </div>
      <div className="pin-code-input-fields">
        {Array.from({ length: 4 }, (_, index) => (
          <div className="pin-code-input-container" key={index}>
            <input
              key={index}
              ref={(ref) => (inputRefs.current[index] = ref)}
              type="number"
              maxLength="1"
              value={pinCode[index] || ""}
              onChange={(e) => handleChangeOTP(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className={`pin-code-input ${
                validationErrors.pinCode ? "error" : ""
              }`}
            />
          </div>
        ))}
      </div>
      {validationErrors.pinCode && (
        <div className="error-message text-xs">{validationErrors.pinCode}</div>
      )}
      {registrationData && (
        <div className="pin-code-resend mt-3">
          <button
            className={`pin-code-resend-link p-button p-component p-button-text ${
              countdown > 0 ? "p-button-disabled" : ""
            }`}
            onClick={handleResend}
            disabled={countdown > 0}
          >
            <span className="pin-code-resend-icon text-xs">
              {countdown > 0 ? (
                <i className="pi pi-spin pi-spinner mr-2 text-xs" />
              ) : (
                <i className="pi pi-sync mr-2 text-xs" />
              )}
              {countdown > 0
                ? `${t("resend")} ${formatTime(countdown)}`
                : `${t("resendCode")}`}
            </span>
          </button>
        </div>
      )}
      <div className="error-message text-xs">{responseMessages}</div>
    </div>
  );
};

export default PinCodeForm;
