import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registrationApi = createApi({
  reducerPath: "registrationApi",
  baseQuery: fetchBaseQuery({
    //baseUrl: "http://localhost:8080/https://discount.silknet.com/api", // proxy to avoid CORS
    baseUrl: "/api",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }), // Set your API base URL here
  endpoints: (builder) => ({
    registerMobileNumber: builder.mutation({
      query: (values) => ({
        url: `/register?mobileNumber=${values.mobileNumber}&personalID=${values.personalID}&firstName=${encodeURIComponent(values.firstName)}&lastName=${encodeURIComponent(values.lastName)}&birthDate=${values.birthDate}&agreeToTermsAndConditions=${values.agreeToTermsAndConditions}&agreedToTransfer=${values.agreedToTransfer}`,
        method: "POST",
        body: JSON.stringify(values),
      }),
      onError: (error) => {
        // Handle error response
        console.error("Registration error:", error);
      },
      onSuccess: (data) => {
        // Handle successful response
        console.log("Registration success:", data);
      },
    }),
    sendPin: builder.mutation({
      query: (values) => ({
        url: `/sendpin?mobileNumber=${values.mobileNumber}&type=${values.type}`,
        method: "POST",
        body: JSON.stringify(values),
      }),
    }),
    confirmRegistration: builder.mutation({
      query: (values) => ({
        url: `/confirmRegistration?mobileNumber=${values.mobileNumber}&pinCode=${values.pinCode}`,
        method: "POST",
        body: JSON.stringify(values),
      }),
      onError: (error) => {
        // Handle error response
        console.error("PinCode error:", error);
      },
      onSuccess: (data) => {
        // Handle successful response
        console.log("PinCode success:", data);
      },
    }),
    confirmQR: builder.mutation({
      query: (values) => ({
        url: `/confirmQR?mobileNumber=${values.mobileNumber}&pinCode=${values.pinCode}`,
        method: "POST",
        body: JSON.stringify(values),
      }),
      onError: (error) => {
        // Handle error response
        console.error("PinCode error:", error);
      },
      onSuccess: (data) => {
        // Handle successful response
        console.log("PinCode success:", data);
      },
    }),
    languageSelector: builder.mutation({
      query: (lang) => ({
        url: `/lang/${lang}`,
        method: "POST",
        body: JSON.stringify({ language: lang }), 
      }),
    }),  
    generateGooglePayToken: builder.mutation({
      query: (data) => ({
        url: `/addToGoogleWallet/${data?.phoneNumber }`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }), 
    generateApplePayToken: builder.mutation({
      query: (data) => ({
        url: `/addToAppleWallet/${data?.phoneNumber }`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),  
  }),
});

export const {
  useRegisterMobileNumberMutation,
  useSendPinMutation,
  useConfirmRegistrationMutation,
  useConfirmQRMutation,
  useLanguageSelectorMutation,
  useGenerateGooglePayTokenMutation,
  useGenerateApplePayTokenMutation,
} = registrationApi;
