import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import TACeng from "./TACeng";
import TACge from "./TACge";

const TermsAndConditionsModal = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const InfoComponent = currentLanguage === "en" ? TACeng : TACge;

  return (
    <div className="mt-2 mb-3">
      <div className="align-items-center justify-content-center">
        <Card
          subTitle={t("TaCofSilkAndWiss")}
        >
          <span className="text-xs">
         <InfoComponent />
          </span>
        </Card>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
