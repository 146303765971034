import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { useLanguageSelectorMutation } from "../../api/registration";
import AsyncStorage from "@react-native-async-storage/async-storage";

import georgiaFlag from "../../flags/georgia.png";
import ukFlag from "../../flags/united-kingdom.png";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [languageSelectorMutation] = useLanguageSelectorMutation();

  const handleChangeLanguage = async (event) => {
    const language = event.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    try {
      await languageSelectorMutation(language);
      await AsyncStorage.setItem("selectedLanguage", language); // Store selected language in AsyncStorage
      // Handle success or additional logic after updating the language
    } catch (error) {
      console.log(error);
      // Handle error if the language update fails
    }
  };

  const languageOptions = [
    {
      label: <img src={georgiaFlag} alt="ka" className="language-icon" />,
      value: "ka",
    },
    {
      label: <img src={ukFlag} alt="en" className="language-icon" />,
      value: "en",
    },
    // Add more language options as needed
  ];

  useEffect(() => {
    const fetchInitialLanguage = async () => {
      try {
        const storedLanguage = await AsyncStorage.getItem("selectedLanguage"); // Retrieve stored language from AsyncStorage
        setSelectedLanguage(storedLanguage || "ka");
        await languageSelectorMutation(storedLanguage || "ka");
        // Handle success or additional logic after initial language fetch
      } catch (error) {
        console.log(error);
        // Handle error if the initial language fetch fails
      }
    };

    fetchInitialLanguage();
  }, []);

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n, selectedLanguage]);

  return (
    <Dropdown
      value={selectedLanguage}
      options={languageOptions}
      onChange={handleChangeLanguage}
      placeholder="Select Language"
      className="text-xs p-inputtext-sm"
      optionLabel="label"
      optionValue="value"
      optionTemplate={(option) => option.label}
      appendTo={document.body}
      dropdownIcon="pi pi-chevron-down"
    />
  );
}

export default LanguageSelector;
