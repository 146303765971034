import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import GooglePayButton from "./GooglePayButton";
import ApplePayButton from "./ApplePayButton";

const QrImageForm = ({ imageSrc }) => {
  const { t } = useTranslation();
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  const isWindows = /Windows/.test(navigator.userAgent);

  const handleDownload = async () => {
    const downloadUrl = `${imageSrc}?download=1`;
    // Create a temporary <a> element and click it to initiate the download
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "qr-code.png";
    link.target = "_blank";
    link.click();
  };

  const pathnameSegments = imageSrc.split("/");
  const phoneNumber = pathnameSegments[5];

  return (
    <div className="mt-2 mb-3">
      <div className="align-items-center justify-content-center centerImage">
        <p className="font-bold">{t("codetogetdiscount")}</p>
        <img src={imageSrc} alt="QR Code" />
        <div>
          <Button
            onClick={handleDownload}
            label={t("downloadQR")}
            className="p-button-rounded p-button-sm p-button-info mt-4"
          />
        </div>
        {/* {isAndroid && <GooglePayButton phoneNumber={phoneNumber} />}
        {isIOS && <ApplePayButton phoneNumber={phoneNumber} />}
        {isWindows && (
          <div>
            <GooglePayButton phoneNumber={phoneNumber} />{" "}
            <ApplePayButton phoneNumber={phoneNumber} />{" "}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default QrImageForm;
