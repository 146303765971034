import { Image } from "primereact/image";

const TermsAndConditionsModalHeader = ({headText}) => {
  return (
    <div className="flex">
      <div className="mt-1 mr-2">
        <Image
          src="../images/logos/logo_icon.png"
          alt="Logo Text"
          className="logo-text"
          width="30"
        />
      </div>
      <div className="flexCenter flex justify-content-center flex-nowrap">
        {headText}
      </div>
    </div>
  );
};

export default TermsAndConditionsModalHeader;
