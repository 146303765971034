import React from "react";
import { InputMask } from "primereact/inputmask";
import { useTranslation } from "react-i18next";

const QrCodeForm = ({
  formValues,
  formErrors,
  handleChangeRestoreQR,
  responseMessages,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-2">
      <div className="align-items-center justify-content-center">
        <label
          htmlFor="mobileNumber"
          className="block text-700 font-small text-sm mb-1 font-bold"
        >
          {t("mobileNumber")}*
        </label>
        <InputMask
          id="mobileNumber"
          mask="999999999"
          value={formValues.mobileNumber}
          onChange={handleChangeRestoreQR}
          slotChar=""
          className="w-full mb-1 p-inputtext-sm"
          placeholder="5XX XXXXXX"
          inputMode="numeric"
        />

        {formErrors.mobileNumber && (
          <div className="text-red-500 text-xs text-right">
            {formErrors.mobileNumber}
          </div>
        )}
      </div>
      <div
        className="align-items-center justify-content-center"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="error-message text-xs">{responseMessages}</div>
      </div>
    </div>
  );
};

export default QrCodeForm;
