import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {
  useSendPinMutation,
  useConfirmRegistrationMutation,
  useConfirmQRMutation,
} from "../../api/registration";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import "./index.css";
import { validateForm } from "../../utils/validation";
import PincodeForm from "./forms/PincodeForm";
import QrCodeForm from "./forms/QrCodeForm";
import QrImageForm from "./forms/QrImageForm";
import TermsAndConditionsModal from "./forms/TermsAndConditionsModal";
import AgreedToTransferModal from "./forms/AgreedToTransferModal";
import TermsAndConditionsModalHeader from "./forms/TermsAndConditionsModalHeader";
import { useTranslation } from "react-i18next";
import ErrorForm from "../ModalComponent/forms/ErrorForm";

const ModalComponent = ({
  dialogId,
  visible,
  onHide,
  pinCode,
  setPinCode,
  registrationData,
  agreeToTermsAndConditionsCheckboxChange,
  agreeToTermsAndConditions,
  agreedToTransfer,
  agreedToTransferCheckboxChange,
  toastRef,
  agreeToTransferDataCheckboxChange,
  typeOfModal,
}) => {
  const { t } = useTranslation();
  const [sendPin, { data }] = useSendPinMutation();
  const [confirmPin, { data: confirmPinData, isLoading }] =
    useConfirmRegistrationMutation();
  const [confirmQR, { data: confirmQRData }] = useConfirmQRMutation();
  const [validationErrors, setValidationErrors] = useState({});
  const [countdown, setCountdown] = useState(120); // Initial countdown value in seconds
  const [formErrors, setFormErrors] = useState({});
  const [currentForm, setCurrentForm] = useState(dialogId);
  const [responseMessages, setResponseMessages] = useState("");

  const [formValues, setFormValues] = useState({
    mobileNumber: "",
  });

  useEffect(() => {
    if (visible) {
      setCountdown(registrationData?.wait_time_left); // Reset countdown when modal becomes visible
      setCurrentForm(dialogId); // Update the current form when the modal becomes visible
    }
  }, [visible]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    if (data) {
      // Handle data from the sendPin mutation
      setCountdown(registrationData?.wait_time_left) ||
        setCountdown(data?.data?.wait_time_left); // Activate the countdown
    }
  }, [data]);

  const handleChangeRestoreQR = (e) => {
    const { id, value } = e.target;
    let convertedValue = value;

    // Update form values
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: convertedValue,
    }));

    // Validate the field and update form errors
    const validationErrors = validateForm(
      {
        ...formValues,
        [id]: convertedValue,
      },
      t
    );

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: validationErrors[id],
    }));
  };

  const handleModalSubmit = async () => {
    const errors = {};

    // Check if any pin code input is empty
    if (pinCode.length < 4) {
      errors.pinCode = t("Pincodeentryismandatory");
    }

    if (Object.keys(errors).length > 0) {
      // If there are validation errors, set them in state and prevent form submission
      setValidationErrors(errors);
      return;
    }

    try {
      if (typeOfModal === "registrationForm") {
        isLoading && setCurrentForm("showLoadingModalId");
        const response = await confirmPin({
          mobileNumber: registrationData?.mob_num,
          pinCode: pinCode,
        });

        if (
          response?.data?.result === false &&
          response?.data?.data?.error_type === "pin"
        ) {
          setResponseMessages(response?.data?.message);
          setCurrentForm("showPinCodeFormModalId");
        } else {
          setResponseMessages(response?.data?.message);
          setCurrentForm("showErrorModalId");
        }

        // Handle success response
        if (response?.data?.result === true) {
          setCurrentForm("showQRImageModalId");
        }
      }

      if (typeOfModal === undefined) {
        // case to restore QR code
        let response = "";
        if (formValues?.mobileNumber) {
          response = await confirmQR({
            mobileNumber: formValues.mobileNumber,
            pinCode: pinCode,
          });
        }

        if (
          response?.data?.result === false &&
          response?.data?.data?.error_type === "pin"
        ) {
          setResponseMessages(response?.data?.message);
          setCurrentForm("showPinCodeFormModalId");
        } else {
          setResponseMessages(response?.data?.message);
          setCurrentForm("showErrorModalId");
        }

        // Handle success response
        if (response?.data?.result === true) {
          setCurrentForm("showQRImageModalId");
        }
      }
    } catch (error) {
      // Handle error response
      console.error("Confirmation error:", error);

      // Access the error message from the error object
      if (error && error.data) {
        console.error("Error message:", error.data.message);
      }
    }
  };

  useEffect(() => {
    if (pinCode.length === 4) {
      handleModalSubmit();
    }
  }, [pinCode]);

  const handleResend = async () => {
    try {
      (await formValues?.mobileNumber)
        ? sendPin({ mobileNumber: formValues?.mobileNumber })
        : sendPin({ mobileNumber: registrationData?.mob_num });
      setResponseMessages("");
      // Optional: Show success notification or perform any necessary actions
    } catch (error) {
      // Optional: Show error notification or perform error handling
    }
  };

  const handleAgree = () => {
    const isBothCheckboxesChecked =
      agreeToTermsAndConditions && agreedToTransfer;
    if (!agreeToTermsAndConditions) {
      toastRef.current.show({
        severity: "warn",
        summary: `${t("notification.message")}`,
        detail: `${t("youShouldAgree")}`,
        sticky: true,
      });
    } else {
      isBothCheckboxesChecked
        ? onHide()
        : setCurrentForm("showAgreedToTransferModalId");
    }
  };

  const handleAgreeToTransfer = () => {
    const isBothCheckboxesChecked =
      agreeToTermsAndConditions && agreedToTransfer;
    if (!agreedToTransfer) {
      toastRef.current.show({
        severity: "warn",
        summary: `${t("notification.message")}`,
        detail: `${t("youShouldAgree")}`,
        sticky: true,
      });
    } else {
      isBothCheckboxesChecked
        ? onHide()
        : setCurrentForm("showTermsAndConditionsModalId");
    }
  };

  const handleSubmitQRcode = async () => {
    try {
      const mutationResult = await sendPin({
        mobileNumber: formValues.mobileNumber,
        type: "qr",
      });

      if (
        mutationResult?.data?.result === false &&
        mutationResult?.data?.data?.error_type === "pin"
      ) {
        setResponseMessages(mutationResult?.data?.message);
      }

      if (!mutationResult?.data?.result) {
        setResponseMessages(mutationResult?.data?.message);
      } else {
        setCurrentForm("showPinCodeFormModalId"); // Switch to the PincodeForm after submitting the QR code form
      }
    } catch (error) {
      // Optional: Show error notification or perform error handling
    }
  };

  const renderFooter = () => {
    switch (currentForm) {
      case "showTermsAndConditionsModalId":
        return (
          <div>
            <div className="mt-2 mb-3">
              <div className="flex align-items-center">
                <Checkbox
                  id="agreeToTermsAndConditions"
                  onChange={agreeToTermsAndConditionsCheckboxChange}
                  checked={agreeToTermsAndConditions}
                  className="mr-2"
                />

                <label
                  htmlFor="agreedToTransfer"
                  className={classNames("text-xs")}
                >
                  {`${t("iAgree")}`}{" "}
                  {`${t("termsandconditions")}`}
                  {/*<a*/}
                  {/*  href="../documents/termsandcond.pdf"*/}
                  {/*  target="_blank"*/}
                  {/*>*/}
                  {/*  {`${t("termsandconditions")}`}*/}
                  {/*</a>*/}
                </label>
              </div>
            </div>
            <div className="mt-1 flex justify-content-center">
              <Button
                label={`${t("decline")}`}
                onClick={(e) => {
                  agreeToTermsAndConditionsCheckboxChange(e);
                  onHide();
                }}
                className="p-button-rounded p-button-sm p-button-secondary"
              />
              <Button
                label={`${t("continue")}`}
                onClick={handleAgree}
                className="p-button-rounded p-button-sm p-button-info"
              />
            </div>
          </div>
        );
      case "showAgreedToTransferModalId":
        return (
          <div>
            <div className="mt-2 mb-3">
              <div className="flex align-items-center">
                <Checkbox
                  id="agreedToTransfer"
                  onChange={agreeToTransferDataCheckboxChange}
                  checked={agreedToTransfer}
                  className="mr-2"
                />

                <label
                  htmlFor="agreedToTransfer"
                  className={classNames("text-xs")}
                >
                  {`${t("iAgree")}`}{" "}
                </label>
              </div>
            </div>
            <div className="mt-1 flex justify-content-center">
              <Button
                label={`${t("decline")}`}
                onClick={(e) => {
                  agreedToTransferCheckboxChange(e);
                  onHide();
                }}
                className="p-button-rounded p-button-sm p-button-secondary"
              />
              <Button
                label={`${t("continue")}`}
                onClick={handleAgreeToTransfer}
                className="p-button-rounded p-button-sm p-button-info"
              />
            </div>
          </div>
        );
      case "showQrCodeModalId":
        return (
          <div className="flex justify-content-center">
            <Button
              label={`${t("continue")}`}
              onClick={handleSubmitQRcode}
              className="p-button-rounded p-button-sm p-button-info"
            />
          </div>
        );
      case "showErrorModalId":
        return (
          <div className="flex justify-content-center">
            <Button
              label={`Ok`}
              onClick={onHide}
              className="p-button-rounded p-button-sm p-button-info"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderHeader = () => {
    switch (currentForm) {
      case "showTermsAndConditionsModalId":
        return (
          <TermsAndConditionsModalHeader
            headText={`${t("readandagreetotheterms")}`}
          />
        );
      case "showAgreedToTransferModalId":
        return (
          <TermsAndConditionsModalHeader
            headText={`${t("consentForPdataP")}`}
          />
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (currentForm) {
      case "showPinCodeFormModalId":
        return (
          <>
            <PincodeForm
              registrationData={registrationData || data?.data?.mob_num}
              pinCode={pinCode}
              validationErrors={validationErrors}
              countdown={countdown}
              setPinCode={setPinCode}
              setValidationErrors={setValidationErrors}
              handleResend={handleResend}
              formValues={formValues}
              responseMessages={responseMessages}
              setResponseMessages={setResponseMessages}
            />
          </>
        );
      case "showTermsAndConditionsModalId":
        return <TermsAndConditionsModal />;
      case "showAgreedToTransferModalId":
        return <AgreedToTransferModal />;
      case "showErrorModalId":
        return <ErrorForm responseMessages={responseMessages} />;
      case "showQrCodeModalId":
        return (
          <QrCodeForm
            formValues={formValues || data?.data}
            formErrors={formErrors}
            handleChangeRestoreQR={handleChangeRestoreQR}
            responseMessages={responseMessages}
          />
        );
      case "showQRImageModalId":
        return (
          <QrImageForm
            imageSrc={
              confirmQRData?.data?.qr_img || confirmPinData?.data?.qr_img
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog
        visible={visible}
        onHide={onHide}
        modal
        header={renderHeader()}
        footer={renderFooter()}
        className={`${
          dialogId === "showPinCodeFormModalId" || "showQrCodeModalId"
            ? "pin-code-modal"
            : ""
        }`}
        style={
          dialogId === "showTermsAndConditionsModalId" ||
          dialogId === "showAgreedToTransferModalId"
            ? { width: "700px" }
            : {}
        }
      >
        {renderContent()}
      </Dialog>
    </>
  );
};

export default ModalComponent;
