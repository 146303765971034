import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import PInfoEng from "./PInfoEng";
import PInfoGe from "./PInfoGe";

const AgreedToTransferModal = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const InfoComponent = currentLanguage === "en" ? PInfoEng : PInfoGe;

  return (
    <div className="mt-2 mb-3">
      <div className="align-items-center justify-content-center">
        <Card subTitle={<strong>{t("personalInfoAgree")}</strong>}>
          <span className="text-xs">
            <InfoComponent />
          </span>
        </Card>
      </div>
    </div>
  );
};

export default AgreedToTransferModal;
